import { NetcurioButton, NetcurioDialog } from '@netcurio/frontend-components'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface UnblockUserModalProps {
	open: boolean
	close: () => void
	onAccept: () => void
}

/**
 * UnBlockUserModal
 * @description Helps to display a message when user tries to save modifications to a product
 * @param open <boolean>: tells if the dialog is open or not
 * @param close <function>: this function handles any action on the parent when close action is triggered
 * @param onAccept <function>: this function handles any action on the parent when clicking 'Accept' button
 * @returns Modal component
 */
export const SaveChangesModal = ({ open, close, onAccept }: UnblockUserModalProps) => {
	const { t } = useTranslation()

	const triggerClose = () => {
		close()
	}

	const triggerUnBlock = () => {
		onAccept()
	}

	return (
		<NetcurioDialog
			open={open}
			onClose={triggerClose}
			titleText={t('saveEditionTitle')}
			actionButtons={
				<>
					<NetcurioButton variant="text" onClick={triggerClose}>
						{t('goBackText')}
					</NetcurioButton>
					<NetcurioButton variant="contained" onClick={triggerUnBlock}>
						{t('acceptTextButton')}
					</NetcurioButton>
				</>
			}
		>
			<p>{t('saveEditionQuestion')}</p>
		</NetcurioDialog>
	)
}
