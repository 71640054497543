import { NetcurioButton, NetcurioDialog } from '@netcurio/frontend-components'
import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

interface UnsubscribeUserModalProps {
	open: boolean
	close: () => void
	accept: () => void
	email: string
}

/**
 * UnsubscribeUserModal
 * @description Helps to display a message when user wants to unsubscribe another user
 * @param open <boolean>: tells if the dialog is open or not
 * @param close <function>: this function handles any action on the parent when close action is triggered
 * @param unblock <function>: this function handles any action on the parent when unblocking action is triggered
 * @param email <string>: the user´s email
 * @returns Modal component
 */
export const UnsubscribeUserModal = ({
	open,
	close,
	accept,
	email
}: UnsubscribeUserModalProps): ReactElement => {
	const { t } = useTranslation()

	const handleClose = () => {
		close()
	}

	const handleAccept = () => {
		accept()
	}
	return (
		<NetcurioDialog
			open={open}
			onClose={handleClose}
			titleText={t('unsuscribeUser')}
			actionButtons={
				<>
					<NetcurioButton variant="text" onClick={handleClose}>
						{t('comeBackTextModal')}
					</NetcurioButton>
					<NetcurioButton variant="contained" onClick={handleAccept}>
						{t('acceptTextButton')}
					</NetcurioButton>
				</>
			}
		>
			<p>{t('unsubscribeUserQuestion', { email })}</p>
		</NetcurioDialog>
	)
}
