import { NetcurioButton, NetcurioDialog } from '@netcurio/frontend-components'
import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

interface BlockUserModalProps {
	open: boolean
	close: () => void
	block: () => void
	email: string
}

/**
 * BlockUserModal
 * @description Helps to display a message when user try to block another user
 * @param open <boolean>: tells if the dialog is open or not
 * @param close <function>: this function handles any action on the parent when close action is triggered
 * @param block <function>: this function handles any action on the parent when blocking action is triggered
 * @param email <string>:
 * @returns Modal component
 */
export const BlockUserModal = ({ open, close, block, email }: BlockUserModalProps): ReactElement => {
	const { t } = useTranslation()

	const triggerClose = () => {
		close()
	}

	const triggerBlock = () => {
		block()
	}

	return (
		<NetcurioDialog
			open={open}
			onClose={triggerClose}
			titleText={t('blockUser')}
			actionButtons={
				<>
					<NetcurioButton variant="text" onClick={triggerClose}>
						{t('goBackText')}
					</NetcurioButton>
					<NetcurioButton variant="contained" onClick={triggerBlock}>
						{t('block')}
					</NetcurioButton>
				</>
			}
		>
			<p>{t('blockUserQuestion', { email: email })}</p>
		</NetcurioDialog>
	)
}
