import { NetcurioButton, NetcurioDialog } from '@netcurio/frontend-components'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface UnblockUserModalProps {
	open: boolean
	close: () => void
	unblock: () => void
	email: string
}

/**
 * UnBlockUserModal
 * @description Helps to display a message when user try to unblock another user
 * @param open <boolean>: tells if the dialog is open or not
 * @param close <function>: this function handles any action on the parent when close action is triggered
 * @param unblock <function>: this function handles any action on the parent when unblocking action is triggered
 * @param email <string>:
 * @returns Modal component
 */
export const UnblockUserModal = ({ open, close, unblock, email }: UnblockUserModalProps) => {
	const { t } = useTranslation()

	const triggerClose = () => {
		close()
	}

	const triggerUnBlock = () => {
		unblock()
	}

	return (
		<NetcurioDialog
			open={open}
			onClose={triggerClose}
			titleText={t('unblockUser')}
			actionButtons={
				<>
					<NetcurioButton variant="text" onClick={triggerClose}>
						{t('goBackText')}
					</NetcurioButton>
					<NetcurioButton variant="contained" onClick={triggerUnBlock}>
						{t('unblock')}
					</NetcurioButton>
				</>
			}
		>
			<p>{t('unblockUserQuestion', { email: email })}</p>
		</NetcurioDialog>
	)
}
